import "prometheus.aaa.scss";
import "prometheus.modern.scss";

import "./theme.aaa.scss";
import "./theme.modern.scss";


import App from "./lib/App/App.svelte";

// @ts-ignore
const i18n = window.profileCardTerms;

// @ts-ignore
const newProfileCardEnabled = window.newProfileCardEnabled;

const app = newProfileCardEnabled ? new App({
    target: document.getElementById("itsl-profile-card-dialog"),
    props: {
        i18n
    }
}) : null;

export default app;
